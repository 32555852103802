<template>
  <div class="facilities-edit animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle lineBottom">Facility Content</h4>
          <div class="main-contents">
            <fd-input
              class="mt-2"
              type="text"
              :label="`Facility Name`"
              v-model="facility.name"
              :required="true"
            />
            <fd-textarea
              class="mt-2"
              :label="`Facility Description`"
              v-model="facility.description"
              :rows="8"
            />
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Update</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { FacilityModel } from "@/models";

export default {
  name: "facilities-edit",
  data() {
    return {
      facility: {
        name: "",
        description: ""
      }
    };
  },
  mounted() {
    this.loadFacility();
  },
  methods: {
    async loadFacility() {
      const id = this.$route.params.id;
      try {
        const request = await this.$store.dispatch(
          "manageFacilities/getFacility",
          id
        );

        this.facility = FacilityModel.getFacilityResponse(
          this._.cloneDeep(request)
        );

        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const id = this.$route.params.id;
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "manageFacilities/updateFacilities",
          {
            id: id,
            data: FacilityModel.postFacilityPayload(this.facility)
          }
        );

        alert("Facility updated successfully.");
        this.$router.push({ path: "/manage-facilities" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.facilities-edit {
  form {
    @extend %formDesign;
  }
}
</style>